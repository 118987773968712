import type { LoginResponse } from '@/components/account/types'
import { API_BASE, API_ENDPOINT_AUTH_LOGIN, SHARED_COOKIE_DOMAIN, ACCOUNT_URL, PORTAL_URL } from '@/constants/index.js'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/composables/useModal'

const TWO_FACTOR_MODAL_ID = 'two-factor-code-verification-modal'
const loading = ref(false)

type LoginRequestBody = {
	email: string
	password: string
	twoFactorCode?: number
}

class LoginError extends Error {
	status: number
	detail: string
	constructor (message: string, status: number, detail: string) {
		super(message)
		this.name = 'LoginError'
		this.status = status
		this.detail = detail
	}
}

export function useLogin () {
	const { reportError } = useErrorReporter(useBugsnag().notify)
	const { openModal, closeModal } = useModal()
	const { t } = useI18n({
		useScope: 'global'
	})

	const login = async (email: string, password: string, twoFactorCode?: number) => {
		const formData: LoginRequestBody = {
			email,
			password
		}

		if (twoFactorCode) {
			formData.twoFactorCode = twoFactorCode
		}

		loading.value = true

		return await $fetch<LoginResponse>(`${API_BASE}${API_ENDPOINT_AUTH_LOGIN}`, {
			method: 'POST',
			credentials: 'include',
			body: formData,
			onResponse ({ response }) {
				if (!response.ok) {
					const error = response._data?.errors?.[0] || response._data
					throw new LoginError(error.title || 'Request - Error submitting login credentials', error.status || 500, error.detail?.errorCode || '')
				} else if (response.status === 200) {
					if (response._data?.success) {
						if (formData.twoFactorCode) {
							closeModal()
						}
						const redirectCookie = useCookie('redirected-from', {
							domain: SHARED_COOKIE_DOMAIN
						})
						const path = redirectCookie?.value || ''
						redirectCookie.value = ''
						let redirectPath = ''
						if (path.includes('kiosk')) {
							navigateTo(path)
							return
						} else if (response?._data?.redirect === 'portal') {
							redirectPath = `${PORTAL_URL}${path}`
						} else {
							redirectPath = `${ACCOUNT_URL}${path}`
						}
						window.location.href = redirectPath
					} else {
						reportError('Error: Invalid login credentials', true, { title: t('login.error-toast') })
					}
				}
			}
		}).catch((e) => {
			if (e.detail === 'AUT-018') {
				// show 2FA modal but don't show the error toast
				openModal(TWO_FACTOR_MODAL_ID)
			} else {
				reportError(e, true, {
					title: e.status === 429 ? t('login.attempts-exceeded') : t('login.error-toast'),
					message: e.status === 429 ? t('login.attempts-exceeded-message') : t('login.error-toast-message')
				})
			}
		}).finally(() => {
			loading.value = false
		})
	}

	return {
		loading,
		login,
		TWO_FACTOR_MODAL_ID
	}
}
